body {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(41, 9, 121, 0.7000061377089899) 35%,
    rgba(0, 212, 255, 1) 100%
  );
}
.container {
  display: flex;
  flex-direction: column;
}

.lo {
  display: flex;
  margin-top: 60px;
  margin-left: 140px;
}

.txt1 {
  font-size: 38px;
  margin-left: 40px;
  margin-top: 50px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: rgb(255, 106, 0);
}

.para {
  font-size: 24px;
  margin-left: 330px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: rgb(255, 106, 0);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.inp {
  display: block;
  background: transparent;
  width: 70%;
  padding: 10px;
  margin-top: 10px;
  border: 2px solid rgb(255, 106, 0);
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.inp-label {
  display: inline-block;
}
.bttt{
  display: flex;
  margin-top: 20px;
}
.btns {
  margin-top: 20px;
  margin-left: 200px;
}
.btn {
  margin-top: 20px;
  margin-left: 70px;
}


.button {
  border: 2px solid rgb(255, 106, 0);
  border-radius: 6px;
  padding: 10px 20px;
  cursor: pointer;
  background-color: rgb(255, 106, 0);
  color: white;
  font-size: 18px;
}


.button:hover {
  border: 2px solid rgb(255, 153, 0);
  background-color: rgb(255, 153, 0);
}

.result-img {
  max-width: 500px;
  margin-top: 20px;
}
@media (min-width:100px)and (max-width: 600px) {
  .lo{
    margin: auto;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .logo {
    align-items: center;
    text-align: center;
    width: 80px;
    height: 80px;
  }

  .txt1 {
    font-size: 1.5rem;
  }
  .bttt{
    margin: auto;
    align-items: center;
  }
  .btns {
    margin-top: 20px;
    margin-left: 20px;
  }
  .para {
    font-size: 2rem;
    margin:auto;
    text-align: center;
  }
}